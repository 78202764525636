
import request from '@/utils/request'
const baseApi = '/api-push'

// 获取统计数据
export function getCountInfo(data) {
  return request({
    url: `${baseApi}/message/getCountInfo`,
    method: 'post',
    data
  })
}
// 获取统计数据
export function getCount(data) {
  return request({
    url: `${baseApi}/message/getCount`,
    method: 'post',
    data
  })
}
// 修改通知消息状态
export function pushUpdate(data) {
  return request({
    url: `${baseApi}/push/update`,
    method: 'post',
    data
  })
}
