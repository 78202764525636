<template>
  <div class="location">
    <div id="map" class="map" />
    <div ref="point" class="point">
      <div class="notice">
        <div class="pic">
          <img :src="noticeIcon" class="img">
        </div>
        <div v-if="managerNum > 0" class="cont">
          <div class="title">您同城超过<span class="num">{{ managerNum }}个</span>经理人</div>
          <div class="des">最近的5分钟内可上门办理贷款</div>
        </div>
        <div v-else class="cont">
          <div class="title">附近没有经理人</div>
        </div>
      </div>
      <img :src="locationIcon" class="icon">
    </div>
    <div class="center" @click="goCenter">
      <img :src="centerIcon" class="img">
    </div>
    <div class="about" @click="goAbout">
      <img :src="aboutIcon" class="icon">
      一分钟了解猫嘀嘀 <van-icon name="arrow" />
    </div>
    <template v-if="salesmanEaUserName">
      <div class="apply" @click="goLoan">
        立即联系
      </div>
    </template>
    <template v-else>
      <template v-if="location.cityCode && !btnStatus">
        <div class="apply" @click="goLoan">
          我要贷款
        </div>
      </template>
      <template v-else>
        <div class="apply disable">
          我要贷款
        </div>
      </template>
    </template>
    <div class="city" @click="goCityList">
      <img :src="cityIcon" class="icon">
      {{ location.areaName }}
      <img :src="downIcon" class="down">
    </div>
    <van-popup v-model="redShow" get-container="#red-popup">
      <div class="red-popup">
        <div class="close"><van-icon name="cross" @click="closeRed" /></div>
        <div class="btn" @click="goRedList">
          <img :src="redIcon" alt="">
        </div>
      </div>
    </van-popup>
    <van-popup v-model="salesmanMsgShow" get-container="#salesman-popup" class="salesman-van-popup" round>
      <div class="salesman-msg-popup">
        <div class="notice">经理人 &lt;{{ salesmanMsg.saleName }}> 发来一条消息</div>
        <div class="btn">
          <van-button
            round
            block
            type="info"
            color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
            @click="goChatRoom(salesmanMsg.saleEaUserName)"
          >
            立即联系
          </van-button>
        </div>
        <div class="close"><van-icon name="close" @click="() => { salesmanMsgShow = false }" /></div>
      </div>
    </van-popup>
    <van-popup v-model="loanSuccessShow" get-container="#loan-success-popup" class="salesman-van-popup" round>
      <div class="loan-success-popup">
        <div class="icon">
          <img :src="loanSuccessIcon" alt="">
        </div>
        <div class="notice">恭喜你贷款成功啦！</div>
        <div class="btn">
          <van-button
            round
            block
            type="info"
            color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
            @click="goLoanProgress({orderId: loanSuccessOrderId, saleId: loanSuccessSaleId})"
          >
            查看贷款详情
          </van-button>
          <div class="share" @click="goShare">分享给好友</div>
        </div>
        <div class="close"><van-icon name="close" @click="closeLoanSuccess" /></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { initMap } from '@/utils/map'
import locationIcon from '@/assets/location/current_location@2x.png'
import noticeIcon from '@/assets/location/notice.png'
import centerIcon from '@/assets/location/relocate@2x.png'
import aboutIcon from '@/assets/location/notification@2x.png'
import cityIcon from '@/assets/location/locate@2x.png'
import downIcon from '@/assets/drop-down@2x.png'
import boyIcon from '@/assets/location/male@2x.png'
import girlIcon from '@/assets/location/female@2x.png'
import redIcon from '@/assets/location/open@2x.png'
import loanSuccessIcon from '@/assets/location/loan_success.png'
import { setLocalUserInfo, getLocalUserInfo } from '@/utils/auth'
import { nearbyManager, orderState, checkCity, redCouponsList } from '@/api/user'
import { h5bioAssayUrl } from '@/api/product'
import { getCountInfo, pushUpdate } from '@/api/push'
import { mapGetters } from 'vuex'
import { setShare } from '@/utils/wx.config'
export default {
  name: 'Location',
  data() {
    return {
      map: null,
      AMap: null,
      btnStatus: true,
      location: {
        latitude: '31.230372',
        longitude: '121.473662',
        areaName: '定位中',
        adCode: '',
        cityCode: '',
        cityCodeBySwitch: ''
      },
      orderId: '',
      orderState: null,
      salesmanEaUserName: '',
      managerNum: 0,
      redShow: false,
      redDatas: null,
      salesmanMsgShow: false,
      salesmanMsg: {},
      loanSuccessShow: false,
      loanSuccessOrderId: null,
      loanSuccessSaleId: null,
      locationIcon,
      noticeIcon,
      centerIcon,
      aboutIcon,
      cityIcon,
      downIcon,
      boyIcon,
      girlIcon,
      redIcon,
      loanSuccessIcon
    }
  },
  computed: {
    ...mapGetters([
      'firstEnter',
      'shareUrls'
    ])
  },
  async mounted() {
    console.log(111)
    this.btnStatus = true
    await this.goLocation()
    this.btnStatus = false
  },
  methods: {
    goShare() {
      console.log(11)
      this.$toast(
        {
          message: '请点击右上角三个点，选择转发给朋友进行分享！',
          teleport: '#toast',
          zIndex: 9999
        }
      )
    },
    async initWx() {
      await this.$store.dispatch('wx/initWxConfig', window.location.href.split('#')[0])
      await this.$store.dispatch('user/getShareUrls', {
        keys: [
          'invite_share'
        ]
      })
      const link = this.shareUrls.invite_share
      setShare({
        title: '用最少的钱，贷更多的钱',
        desc: '猫嘀嘀推崇客户至上的理念，让您体验极致贷款服务',
        link,
        imgUrl: 'https://www.maodidikeji.com/user/assets/logo.png'
      })
    },
    async closeRed() {
      this.redShow = false
      await this.getCountInfo()
    },
    async closeLoanSuccess() {
      this.loanSuccessShow = false
    },
    goRedList() {
      this.$router.push({ name: 'redList', params: { datas: this.redDatas }})
    },
    goLoanProgress({ orderId, saleId }) {
      this.$router.push({ name: 'loanProgress', params: { orderId }, query: { saleId }})
    },
    goChatRoom(your) {
      this.$router.push({ name: 'chatRoom', params: { your }})
    },
    async getCountInfo() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getCountInfo({ opSource: 5 })
      const list = res.datas && res.datas.pushMsg && res.datas.pushMsg.data || []
      for (let i = 0; i < list.length; i++) {
        const pushList = list[i].pushList || []
        for (let j = 0; j < pushList.length; j++) {
          const msgType = pushList[j].msgType
          if (msgType === '31' || msgType === '40') {
            const haveFirst = pushList[j].haveFirst
            if (haveFirst === '0' || !haveFirst) {
              this.salesmanMsg = pushList[j].extra && JSON.parse(pushList[j].extra) || {}
              this.salesmanMsgShow = true
              await pushUpdate({
                opSource: '5',
                id: pushList[j].id,
                haveFirst: '1',
                status: '0',
                type: pushList[j].type
              })
            }
          } else if (msgType === '41') {
            const haveFirst = pushList[j].haveFirst
            if (haveFirst === '0' || !haveFirst) {
              this.initWx()
              this.loanSuccessOrderId = pushList[j].extra && pushList[j].extra.match(/"mainOrderId\":(\d*),/)[1]
              this.loanSuccessSaleId = pushList[j].extra && pushList[j].extra.match(/"saleId\":(\d*),/)[1]
              this.loanSuccessShow = true
              await pushUpdate({
                opSource: '5',
                id: pushList[j].id,
                haveFirst: '1',
                status: '0',
                type: pushList[j].type
              })
            }
          }
        }
      }
      loading.clear()
    },
    async redCouponsList() {
      const res = await redCouponsList()
      this.redDatas = res.datas || []
      if (this.redDatas.length > 0) {
        this.redShow = true
      }
    },
    async goLoan() {
      if (this.salesmanEaUserName) {
        this.$dialog.confirm({
          title: '提示',
          message: '您有一笔贷款正在进行中，是否查看贷款进度？',
          confirmButtonText: '查看进度',
          cancelButtonText: '继续沟通'
        })
          .then(async() => {
            this.$router.push({ name: 'userManager' })
          })
          .catch(() => {
            this.$router.push({ name: 'chatRoom', params: { your: this.salesmanEaUserName }})
          })
      } else {
        const res = await checkCity({ cityCode: this.location.cityCode })
        if (res.datas !== 1) return
        if (this.orderId) {
          this.$dialog.confirm({
            title: '提示',
            message: '您的贷款所需材料已提交，是否立即匹配？',
            cancelButtonText: '修改资料',
            confirmButtonText: '立即匹配',
            confirmButtonColor: '#FF7700'
          })
            .then(() => {
              this.$router.push({ name: 'managerMatch', params: { orderId: this.orderId }})
            })
            .catch(() => {
              this.$router.push({ name: 'loanInfo', query: { orderId: this.orderId }})
            })
        } else {
          // 假如当前无订单但是有最后一笔订单，loaninfo获取最后一笔订单的用户信息
          if (this.orderState.lastOrderId) {
            this.$router.push({ name: 'loanInfo', query: { lastOrderId: this.orderState.lastOrderId }})
          } else {
            this.$router.push({ name: 'loanInfo' })
          }
        }
      }
    },
    goCityList() {
      this.$router.push({ name: 'cityList' })
    },
    goAbout() {
      this.$router.push({ name: 'aboutMdd' })
    },
    goCenter() {
      const { latitude, longitude } = this.location
      this.map.setCenter(new this.AMap.LngLat(longitude, latitude))
    },
    setCenterMaker() {
      const { latitude, longitude } = this.location
      const marker = new this.AMap.Marker({
        position: new this.AMap.LngLat(longitude, latitude), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        content: this.$refs.point,
        anchor: 'center'
      })
      marker.on('click', () => {
        this.$router.push({ name: 'managerCity' })
      })
      // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker)
    },
    async getOrderState(params) {
      const res = await orderState(params)
      this.orderState = res.datas
      if (res.datas.firstIn === 1) { // firstIn 为1的时候为甩单用户第一次登录
        this.$dialog.confirm({
          title: '提示',
          message: '您的贷款所需材料已提交，是否立即联系经理人？',
          cancelButtonText: '修改资料',
          confirmButtonText: '立即联系',
          confirmButtonColor: '#FF7700'
        })
          .then(() => {
            this.$router.push({ name: 'chatRoom', params: { your: res.datas.eaUserName }})
          })
          .catch(() => {
            this.$router.push({ name: 'loanInfo', query: { orderId: this.orderId, firstIn: res.datas.firstIn, your: res.datas.eaUserName }})
          })
      }
      this.orderId = res.datas.orderId
      this.salesmanEaUserName = res.datas.eaUserName
    },
    async getNearbyManager() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const { cityCode, adCode, latitude, longitude } = this.location
      const res = await nearbyManager({ cityCode, adCode, latitude, longitude })
      this.managerNum = res.datas.length
      if (cityCode) {
        await this.getOrderState({ cityCode, adCode, latitude, longitude })
      }
      const markers = []
      res.datas.map(item => {
        if (item.longitude && item.latitude) {
          const icon = new this.AMap.Icon({ image: item.sex === 1 ? this.boyIcon : this.girlIcon, size: new this.AMap.Size(30, 30), imageSize: new this.AMap.Size(30, 30) })
          const position = new this.AMap.LngLat(item.longitude, item.latitude)
          markers.push(new this.AMap.Marker({ position, icon, anchor: 'center' }))
        }
      })
      this.map.add(markers)
      loading.clear()
    },
    async goLocation() {
      const loading = this.$toast.loading({ forbidClick: true, message: '定位中...', duration: 0 })
      this.AMap = await initMap()
      if (this.firstEnter) {
        this.$store.dispatch('user/setFirstEnter', false)
        this.map = new this.AMap.Map('map', {
          zoom: 15
        })
        this.map.plugin('AMap.Geolocation', () => {
          var geolocation = new this.AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
            showMarker: false, // 定位成功后在定位到的位置显示点标记，默认：true
            showCircle: false // 定位成功后用圆圈表示定位精度范围，默认：true
          })
          this.map.addControl(geolocation)
          geolocation.getCurrentPosition(async(status, result) => {
            console.log(status, result, '====result')
            loading.clear()
            if (status === 'complete') {
              // data是具体的定位信息
              const { lat, lng } = result.position
              this.location.latitude = lat
              this.location.longitude = lng
              this.AMap.plugin('AMap.Geocoder', (res) => {
                console.log(res, '1020')
                var geocoder = new this.AMap.Geocoder()

                var lnglat = [lng, lat]

                geocoder.getAddress(lnglat, async(status, result) => {
                  console.log(status, result, '33049')
                  if (status === 'complete' && result.info === 'OK') {
                    const city = result.regeocode.addressComponent
                    // result为对应的地理位置详细信息
                    this.location.cityCode = city.citycode
                    this.location.areaName = city.city
                    this.location.adCode = city.adcode
                    // 将城市定位储存到本地 city 当前选择城市  locationCity 定位城市
                    setLocalUserInfo({ city: { cityCode: city.citycode, areaName: city.city, adCode: city.adcode, latitude: lat, longitude: lng }, locationCity: { cityCode: city.citycode, areaName: city.city, adCode: city.adcode, latitude: lat, longitude: lng }})
                    this.setCenterMaker()
                    await this.getNearbyManager()
                  }
                })
              })
            } else {
              const { city } = getLocalUserInfo()
              if (city) {
                this.location = city
                this.location.cityCodeBySwitch = ''
              } else {
                this.location.areaName = '定位失败'
              }
              this.map = new this.AMap.Map('map', {
                zoom: 15,
                center: new this.AMap.LngLat(this.location.longitude, this.location.latitude)
              })
              this.goCenter()
              this.setCenterMaker()
              await this.getNearbyManager()
            }
            // 失败或者成功都建立中心点
          })
        })
      } else {
        const { city } = getLocalUserInfo()
        if (city) {
          this.location = city
          this.location.cityCodeBySwitch = ''
        } else {
          this.location.areaName = '定位失败'
        }
        this.map = new this.AMap.Map('map', {
          zoom: 15,
          center: new this.AMap.LngLat(this.location.longitude, this.location.latitude)
        })
        this.goCenter()
        this.setCenterMaker()
        await this.getNearbyManager()
      }
      await this.redCouponsList()
      if (this.redDatas.length === 0) {
        await this.getCountInfo()
      }
    },
    async goH5Face() {
      const res = await h5bioAssayUrl({ orderId: this.orderId, redirectUrl: 'www.baidu.com' })
      console.log(res, '22222')
    }
  }
}
</script>

<style lang="scss" scoped>
.location {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9;
  .map {
    width: 100%;
    height: 100%;
  }
  .center {
    position: absolute;
    top: 50%;
    right: 7px;
    z-index: 99;
    .img {
      width: 68px;
      height: 68px;
    }
  }
  .about {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 0;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    color: #FF7700;
    border-radius: 100px 0 0 100px;
    background: #ffe5ce;
    .icon {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
  }
  .point {
    position: relative;
    .notice {
      display: flex;
      align-items: center;
      position: absolute;
      top: -40px;
      left: -70px;
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
      background: #fff;
      .pic {
        .img {
          width: 44px;
          height: 42px;
        }
      }
      .cont {
        padding-left: 10px;
        width: 180px;
        .title {
          font-size: 14px;
          color: #2E3135;
          font-weight: bold;
          .num {
            padding: 0 5px;
            font-size: 16px;
            color: #FF7700;
          }
        }
        .des {
          padding-top: 6px;
          font-size: 12px;
          color: #333333;
        }
      }
    }
    .icon {
      width: 100px;
      height: 100px;
    }
  }
  .apply {
    position: absolute;
    left: 50%;
    bottom: 100px;
    width: 242px;
    height: 75px;
    line-height: 75px;
    text-align: center;
    margin-left: -121px;
    font-size: 18px;
    color: #fff;
    z-index: 99;
    background:url('~@/assets/location/button@2x.png') top center;
    background-size: 100%;
    &.disable {
      background:url('~@/assets/location/button@2x_hide.png') top center;
      background-size: 100%;
    }
  }
  .city {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 0;
    height: 40px;
    padding: 0 16px;
    border-radius: 0 100px 100px 0;
    z-index: 99;
    background: #fff;
    .icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
    .down {
      width: 8px;
      height: 6px;
      margin-left: 5px;
    }
  }
}
#red-popup {
  .van-popup {
    background: none;
  }
  .red-popup {
    width: 306px;
    height: 372px;
    background: url(~@/assets/location/red@2x.png) no-repeat;
    background-size: 100%;
    .close {
      padding: 45px 0 0 250px;
      font-size: 20px;
      color: #d80800;
    }
  }
  .btn {
    padding: 165px 0 0 110px;
    img {
      width: 85px;
      height: 84px;
    }
  }
}
.salesman-msg-popup {
  position: relative;
  width: 270px;
  padding: 23px;
  text-align: center;
  .notice {
    padding-top: 20px;
  }
  .btn {
    padding: 32px 25px 20px 25px;
  }
  .close {
    position: absolute;
    top: 250px;
    left: 50%;
    margin-left: -14px;
    text-align: center;
    font-size: 28px;
    color: #fff;
  }
}
.loan-success-popup {
  position: relative;
  width: 270px;
  padding: 23px;
  text-align: center;
  .icon {
    position: relative;
    img {
      position: absolute;
      left: 50%;
      top: -80px;
      width: 109px;
      margin-left: -55px;
    }
  }
  .notice {
    padding-top: 70px;
  }
  .btn {
    padding: 32px 25px 20px 25px;
    .share {
      padding-top: 16px;
    }
  }
  .close {
    position: absolute;
    top: 280px;
    left: 50%;
    margin-left: -14px;
    text-align: center;
    font-size: 28px;
    color: #fff;
  }
}
.salesman-van-popup {
  overflow: inherit;
  z-index: 9999 !important;
}
</style>
